
import { ref, nextTick, computed, onMounted, onUnmounted } from "../../../adapter-vue";
import TUIChatEngine, { IMessageModel, TUIStore, StoreName, TUITranslateService, TUIChatService, TUIGroupService, IConversationModel } from "@tencentcloud/chat-uikit-engine";
import Link from "./link";
import MessageText from "./message-elements/message-text.vue";
import MessageImage from "./message-elements/message-image.vue";
import MessageAudio from "./message-elements/message-audio.vue";
import MessageFile from "./message-elements/message-file.vue";
import MessageFace from "./message-elements/message-face.vue";
import MessageCustom from "./message-elements/message-custom.vue";
import MessageTip from "./message-elements/message-tip.vue";
import MessageBubble from "./message-elements/message-bubble.vue";
import MessageLocation from "./message-elements/message-location.vue";
import MessageTimestamp from "./message-elements/message-timestamp.vue";
import { compressImageToMaxSize } from '@/assets/js/CompressImageUtils.js';
import MessageVideo from "./message-elements/message-video.vue";
import MessageTool from "./message-tool/index.vue";
import MessageRevoked from "./message-tool/message-revoked.vue";
import MessagePlugin from "../../../plugins/plugin-components/message-plugin.vue";
import MessageGroupSystem from "./message-elements/message-group-system.vue";
import Dialog from "../../common/Dialog/index.vue";
import ImagePreviewer from "../../common/ImagePreviewer/index.vue";
import ProgressMessage from "../../common/ProgressMessage/index.vue";
import Icon from "../../common/Icon.vue";
import doubleArrowIcon from "../../../assets/icon/double-arrow.svg";
import { isCreateGroupCustomMessage } from "../utils/utils";
import { IGroupApplicationListItem } from "../../../interface";
import { getBoundingClientRect, getScrollInfo } from "../../../utils/universal-api/domOperation";
import $Api from "@/module/api/index";
import base from "@/module/api/base";
import { isPC, isH5 } from "../../../utils/env";
interface ScrollConfig {
  scrollToMessage?: IMessageModel;
  scrollToBottom?: boolean;
  scrollToOffset?: {
    top?: number;
    bottom?: number;
  };
}
const __sfc_main = {};
__sfc_main.props = {
  groupID: {
    type: String,
    default: ""
  },
  isGroup: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const messageListRef = ref<HTMLElement>();

  // 上屏展示 messageList，不包含 isDeleted 为 true 的 message
  const messageList = ref<Array<IMessageModel>>();
  const labelKey = {
    isFentin: '是否有高血压',
    isDiabetes: '是否有糖尿病',
    dataisDislipidemia: '是否血脂异常',
    isDrink: '是否饮酒',
    isBeiyun: '是否备孕',
    isBuru: '是否哺乳期',
    isRenshen: '是否妊娠期',
    status: '审核状态',
    createTime: '开处方时间',
    diagnosis: '疾病诊断',
    advice: '医嘱文本',
    rejectReason: '处方审核未通过原因'
  };
  function isState(name, value) {
    let key = {
      isFentin: ['否', '是'],
      isDiabetes: ['否', '是'],
      dataisDislipidemia: ['否', '是'],
      isDrink: ['否', '是'],
      isBeiyun: ['否', '是'],
      isBuru: ['否', '是'],
      isRenshen: ['否', '是'],
      status: ['', '审核通过', '审核未通过']
    };
    return key[name] ? key[name][value * 1] : value;
  }
  // 所有 messageList 序列，包含 isDeleted 为 true 的 message
  const allMessageList = ref<Array<IMessageModel>>();
  const isCompleted = ref(false);
  const currentConversationID = ref("");
  const currentLastMessage = ref<IMessageModel>();
  const currentLastMessageTime = ref<number>(0);
  const nextReqMessageID = ref();
  const toggleID = ref("");
  const TYPES = ref(TUIChatEngine.TYPES);
  const isLongpressing = ref(false);
  const groupApplicationCount = ref(0);
  const showGroupApplication = ref(false);
  const applicationUserIDList = ref<Array<string>>([]);
  const messageTarget = ref<IMessageModel>();
  const messageElementListRef = ref<Array<HTMLElement> | null>();
  const blinkMessageIDList = ref<string[]>([]);
  const beforeHistoryGetScrollHeight = ref<number>(0);
  const uploadUrl = ref(`${base.baseUrl}img?static_path=Pres&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`);
  const isSignalingMessage = (message: IMessageModel) => {
    return message?.type === TYPES.value.MSG_CUSTOM && message?.getSignalingInfo();
  };
  function prescriptionView(item) {
    sessionStorage.setItem('chatState', 'true');
    window.location.href = this.$route.query.pres_url;
  }
  // 图片预览相关
  const showImagePreview = ref(false);
  const currentImagePreview = ref<IMessageModel>();
  const imageMessageList = computed(() => messageList?.value?.filter((item: IMessageModel) => {
    return !item.isRevoked && !item.hasRiskContent && item.type === TYPES.value.MSG_IMAGE;
  }));

  // 消息重发 Dialog
  const reSendDialogShow = ref(false);
  const resendMessageData = ref();

  // 事件监听
  onMounted(() => {
    // 消息 messageList
    TUIStore.watch(StoreName.CHAT, {
      messageList: onMessageListUpdated,
      messageSource: onMessageSourceUpdated,
      isCompleted: isCompletedUpdated
    });
    // 当前 ConversationID
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: onCurrentConversationIDUpdated,
      currentConversation: onCurrentConversationUpdated
    });

    // 群未决消息列表
    TUIStore.watch(StoreName.GRP, {
      groupSystemNoticeList: onGroupSystemNoticeList
    });

    // 群未决消息数量
    TUIStore.watch(StoreName.CUSTOM, {
      groupApplicationCount: groupApplicationCountUpdated
    });
  });
  async function onSuccess(response, file, fileList) {
    let data = {
      orderCode: this.$route.query.pres_id,
      images: response.result.file_name
    };
    $Api.article.OrderImage(data).then(res => {
      res.data.result.value.images;
      localStorage.setItem(res.data.result.value.images, response.result.file_name);
      let promise = TUIChatService.sendCustomMessage({
        payload: {
          data: JSON.stringify({
            type: 7,
            data: [`${base.baseUrl}img?static_path=Pres&file_name=${response.result.file_name}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`].join(';')
          })
        }
      });
    });
  }

  // 取消监听
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.CHAT, {
      messageList: onMessageListUpdated,
      messageSource: onMessageSourceUpdated,
      isCompleted: isCompletedUpdated
    });
    TUIStore.unwatch(StoreName.CONV, {
      currentConversationID: onCurrentConversationIDUpdated,
      currentConversation: onCurrentConversationUpdated
    });
    TUIStore.unwatch(StoreName.GRP, {
      groupSystemNoticeList: onGroupSystemNoticeList
    });
    TUIStore.unwatch(StoreName.CUSTOM, {
      groupApplicationCount: groupApplicationCountUpdated
    });
  });
  const isShowJumpToLatest = computed((): boolean => {
    const lastSuccessMessageIndex = allMessageList.value?.findLastIndex((message: IMessageModel) => message.status === "success");
    return lastSuccessMessageIndex && allMessageList?.value[lastSuccessMessageIndex]?.time < currentLastMessageTime?.value;
  });
  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /** 在图片上传前before-upload钩子函数 */
  function handleBeforeUpload(file) {
    // 使用Promise来确保图片在压缩完成后再进行上传
    return new Promise(async (resolve, reject) => {
      const maxSize = 2048; // 最大文件大小为2MB
      const isSizeExceeded = file.size / 1024 > maxSize; // 检查文件大小是否超过限制

      // 如果文件大小超过限制，则进行压缩
      if (isSizeExceeded) {
        console.log('原始文件:', file);
        const compressedFile = await compressImageToMaxSize(file, maxSize);
        console.log('压缩后的文件:', compressedFile);
        // 直接替换原文件为压缩后的文件
        file = compressedFile;
      }
      resolve(file); // 通过resolve返回压缩后的文件，继续后续操作
    });
  }
  async function onMessageListUpdated(list: Array<IMessageModel>) {
    const oldLastMessage = currentLastMessage.value;
    allMessageList.value = list;
    let messagearr = [];
    list.forEach(element => {
      if (element.payload.data) {
        element.payload.dataInfo = isJson(element.payload.data) ? JSON.parse(element.payload.data) : element.payload.data;
        if (isJson(element.payload.dataInfo.data)) {
          element.payload.dataInfo.data = JSON.parse(element.payload.dataInfo.data);
          if (element.payload.dataInfo.type == 8 && element.payload.dataInfo.data.url !== '') {
            //判断 如果是退款，并且退款连接不为空的时候
            messagearr.push(element);
          }
          if (element.payload.dataInfo.type == 6) {
            messagearr.push(element);
          }
        } else {
          messagearr.push(element);
        }
      } else {
        messagearr.push(element);
      }
    });
    messageList.value = messagearr.filter(message => !message.isDeleted);
    if (!messageList.value?.length) {
      currentLastMessage.value = {};
      return;
    }
    const newLastMessage = messageList.value?.[messageList.value?.length - 1];
    if (messageTarget.value) {
      if (messageList.value?.findIndex((message: IMessageModel) => message?.ID === messageTarget.value?.ID) >= 0) {
        const tempMessage = messageTarget.value;
        messageTarget.value = undefined;
        await scrollToPosition({
          scrollToMessage: tempMessage
        });
        await blinkMessage(tempMessage?.ID);
      }
    } else if (beforeHistoryGetScrollHeight.value) {
      await scrollToPosition({
        scrollToOffset: {
          bottom: beforeHistoryGetScrollHeight.value
        }
      });
      beforeHistoryGetScrollHeight.value = 0;
    } else if (newLastMessage?.ID && JSON.stringify(oldLastMessage) !== JSON.stringify(newLastMessage)) {
      await scrollToPosition({
        scrollToBottom: true
      });
    }
    currentLastMessage.value = Object.assign({}, newLastMessage);
  }
  async function scrollToPosition(config: ScrollConfig = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      requestAnimationFrame(() => {
        if (!messageListRef.value) {
          reject();
        }
        const container = messageListRef.value;
        if (config.scrollToBottom) {
          container!.scrollTop = container!.scrollHeight;
        } else if (config.scrollToMessage) {
          const targetMessageDom = messageElementListRef.value?.find((dom: HTMLElement) => dom?.id === `tui-${config.scrollToMessage?.ID}`);
          if (targetMessageDom?.scrollIntoView) {
            targetMessageDom.scrollIntoView({
              behavior: "smooth",
              block: "nearest"
            });
          }
        } else if (config.scrollToOffset) {
          if (config.scrollToOffset?.top) {
            container!.scrollTop = config.scrollToOffset.top;
          } else if (config.scrollToOffset?.bottom) {
            container!.scrollTop = container!.scrollHeight - config.scrollToOffset.bottom;
          }
        }
        resolve();
      });
    });
  }
  async function onMessageSourceUpdated(message: IMessageModel) {
    // messageSource change 有两种情况
    // 1. messageSource change -> 未命中缓存 -> messageList change,
    // 2. messageSource change -> 命中缓存 -> messageList not change
    // 只有第二种情况需要在此处监听时增加 scrollToTarget
    messageTarget.value = message;
    if (messageTarget.value) {
      if (messageList.value?.findIndex((message: IMessageModel) => message?.ID === messageTarget.value?.ID) >= 0) {
        const tempMessage = messageTarget.value;
        messageTarget.value = undefined;
        await scrollToPosition({
          scrollToMessage: tempMessage
        });
        await blinkMessage(tempMessage?.ID);
      }
    }
  }
  function isCompletedUpdated(flag: boolean) {
    isCompleted.value = flag;
  }
  function groupApplicationCountUpdated(count: number) {
    groupApplicationCount.value = count;
  }

  // 监听回调函数
  const onCurrentConversationIDUpdated = (conversationID: string) => {
    currentConversationID.value = conversationID;
    messageList.value = [];
    // 获取加群系统消息列表
    TUIGroupService.getGroupApplicationList().then((res: any) => {
      const applicationList = res.data.applicationList.filter((application: any) => application.groupID === props.groupID);
      applicationUserIDList.value = applicationList.map((application: IGroupApplicationListItem) => {
        return application.applicationType === 0 ? application.applicant : application.userID;
      });
      TUIStore.update(StoreName.CUSTOM, "groupApplicationCount", applicationList.length);
    });
  };
  const onCurrentConversationUpdated = (conversation: IConversationModel) => {
    currentLastMessageTime.value = conversation?.lastMessage?.lastTime || 0;
  };

  // operationType 操作类型 1: 有用户申请加群   23: 普通群成员邀请用户进群
  const onGroupSystemNoticeList = (list: Array<IMessageModel>) => {
    const systemNoticeList = list.filter(message => {
      const {
        operationType
      } = message.payload;
      return (operationType === 1 || operationType === 23) && message.to === props.groupID;
    });
    systemNoticeList.forEach(systemNotice => {
      const {
        operationType
      } = systemNotice.payload;
      if (operationType === 1) {
        const {
          operatorID
        } = systemNotice.payload;
        if (!applicationUserIDList.value.includes(operatorID)) {
          applicationUserIDList.value.push(operatorID);
        }
      }
      if (operationType === 23) {
        const {
          inviteeList
        } = systemNotice.payload;
        inviteeList.forEach((invitee: string) => {
          if (!applicationUserIDList.value.includes(invitee)) {
            applicationUserIDList.value.push(invitee);
          }
        });
      }
    });
    const applicationCount = applicationUserIDList.value.length;
    TUIStore.update(StoreName.CUSTOM, "groupApplicationCount", applicationCount);
  };
  const toggleApplicationList = () => {
    showGroupApplication.value = !showGroupApplication.value;
  };
  const handleGroupApplication = (userID: string) => {
    const index = applicationUserIDList.value.indexOf(userID);
    if (index !== -1) {
      applicationUserIDList.value.splice(index, 1);
    }
  };

  // 获取历史消息
  const getHistoryMessageList = () => {
    TUIChatService.getMessageList().then((res: any) => {
      const {
        nextReqMessageID: ID
      } = res.data;
      nextReqMessageID.value = ID;
    });
    // 获取历史消息后，保持滚动条在原来位置
    beforeHistoryGetScrollHeight.value = messageListRef.value?.scrollHeight;
  };
  const openComplaintLink = (type: any) => {
    window.open(type.url);
  };

  // 图片预览
  const handleImagePreview = (message: IMessageModel) => {
    if (showImagePreview.value || currentImagePreview.value || isLongpressing.value) {
      return;
    }
    showImagePreview.value = true;
    currentImagePreview.value = message;
  };
  // 关闭图片预览
  const onImagePreviewerClose = () => {
    showImagePreview.value = false;
    currentImagePreview.value = null;
  };

  // 消息操作
  const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
    if (isLongpress) {
      isLongpressing.value = true;
    }
    toggleID.value = message.ID;
  };
  const handleToggleMessageItemForPC = (e: MouseEvent, message: IMessageModel) => {
    if (isPC) {
      toggleID.value = message.ID;
    }
  };

  // h5 long press
  let timer: number;
  const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
    if (!isH5) return;
    function longPressHandler() {
      clearTimeout(timer);
      handleToggleMessageItem(e, message);
    }
    function touchStartHandler() {
      timer = setTimeout(longPressHandler, 500);
    }
    function touchEndHandler() {
      clearTimeout(timer);
    }
    switch (type) {
      case "touchstart":
        touchStartHandler();
        break;
      case "touchend":
        touchEndHandler();
        setTimeout(() => {
          isLongpressing.value = false;
        }, 200);
        break;
    }
  };

  // 消息撤回后，编辑消息
  const handleEdit = (message: IMessageModel) => {
    emits("handleEditor", message, "reedit");
  };

  // 重发消息
  const resendMessage = (message: IMessageModel) => {
    reSendDialogShow.value = true;
    resendMessageData.value = message;
  };
  const resendMessageConfirm = () => {
    reSendDialogShow.value = !reSendDialogShow.value;
    const messageModel = resendMessageData.value;
    messageModel.resendMessage();
  };

  // 回到最新消息
  const jumpToLatestMessage = () => {
    TUIStore.update(StoreName.CHAT, "messageSource", undefined);
    blinkMessageIDList.value = [];
  };
  function blinkMessage(messageID: string): Promise<void> {
    return new Promise(resolve => {
      const index = blinkMessageIDList.value.indexOf(messageID);
      if (index < 0) {
        blinkMessageIDList.value.push(messageID);
        let timer = setTimeout(() => {
          blinkMessageIDList.value.splice(blinkMessageIDList.value.indexOf(messageID), 1);
          clearTimeout(timer);
          resolve();
        }, 3000);
      }
    });
  }

  // 滚动到最新消息
  async function scrollToLatestMessage() {
    const {
      scrollHeight
    } = await getScrollInfo("#messageScrollList");
    const {
      height
    } = await getBoundingClientRect("#messageScrollList");
    const messageListDom = document.querySelector("#messageScrollList");
    if (messageListDom) {
      messageListDom.scrollTop = scrollHeight - height;
    }
  }
  return {
    TUITranslateService,
    doubleArrowIcon,
    isCreateGroupCustomMessage,
    isPC,
    messageListRef,
    messageList,
    labelKey,
    isState,
    isCompleted,
    toggleID,
    TYPES,
    groupApplicationCount,
    showGroupApplication,
    messageElementListRef,
    blinkMessageIDList,
    uploadUrl,
    isSignalingMessage,
    showImagePreview,
    currentImagePreview,
    imageMessageList,
    reSendDialogShow,
    onSuccess,
    isShowJumpToLatest,
    handleBeforeUpload,
    toggleApplicationList,
    handleGroupApplication,
    getHistoryMessageList,
    handleImagePreview,
    onImagePreviewerClose,
    handleToggleMessageItem,
    handleToggleMessageItemForPC,
    handleH5LongPress,
    handleEdit,
    resendMessage,
    resendMessageConfirm,
    jumpToLatestMessage,
    blinkMessage
  };
};
__sfc_main.components = Object.assign({
  MessageTimestamp,
  MessageTip,
  MessageBubble,
  MessageText,
  ProgressMessage,
  MessageImage,
  MessageVideo,
  MessageAudio,
  MessageFile,
  MessageFace,
  MessageLocation,
  MessagePlugin,
  MessageRevoked,
  MessageTool,
  Icon,
  Dialog,
  ImagePreviewer,
  MessageGroupSystem
}, __sfc_main.components);
export default __sfc_main;
